import React from "react";

// antd
import { Row, Col } from "antd";

// component
import FrontDivJcoin from "src/components/Front/Divider/DivJcoin";

const Section5 = () => {
  return (
    <>
      <Row justify="center" style={{ marginTop: 70, paddingBottom: 70 }}>
        <Col span={20} id="rule">
          <FrontDivJcoin title={"　活動規則　"} />
        </Col>
        <Col span={20} md={20} sm={20} xs={20}>
          <div className="text-left">
            <ol>

              <li>
                參加資格：
                任何人。
              </li>

              <li>
                活動期間：
                2025年1月6日至2025年2月28日止，期間認定將以該案件申請日為準。
              </li>

              <li>
              注意事項：
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                  近三個月內若有申請案件且有審核結果，將不可再送件。
                  </li>
                  <li>
                  抽獎獎項皆為限量，發完為止
                  </li>
                  <li>
                  審核標準將依每一個案件個別評估，提供完整文件可加速審核過程及提高核准率。
                  </li>
                  <li>
                  JetShop保有最終解釋、調整與修改本活動辦法之權利。
                  </li>
                </ul>
              </li>

            </ol>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Section5;
