import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { Grid } from "antd";

// css
import "./FrontJetDaiActivity.css";
import "src/pages/Front/JetDai/FrontJetDai.css";

// component
import FrontBreadcrumb from "src/components/Front/Breadcrumb";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";

// image
import jetdaiActivityBackground01 from "src/assets/jetdai/jetdai-activity/background_01.png";
import jetdaiActivityBackground02 from "src/assets/jetdai/jetdai-activity/background_02.png";
import jetdaiActivityBackgroundMobile01 from "src/assets/jetdai/jetdai-activity/background_mobile.png";
import jetdaiActivityBackgroundMobile02 from "src/assets/jetdai/jetdai-activity/background_mobile_02.png";

const { useBreakpoint } = Grid;

const FrontGuide = () => {
  // jet貸活動時間：2024/03/11 00:00:00~2024/04/01 23:59:59(活動結束後網頁才消失)
  const [today] = useState(dayjs());
  const [endDate] = useState(dayjs("2025-01-31 23:59:59"));
  const [isInActivity, setIsInActivity] = useState(false);
  const [bannerImage, setBannerImage] = useState(jetdaiActivityBackground01);
  const [mobileBannerImage, setMobileBannerImage] = useState(jetdaiActivityBackgroundMobile01);
  const screens = useBreakpoint();

  useEffect(() => {
    setIsInActivity(today.isBefore(endDate));

    // Change image based on date
    const imageChangeDate = dayjs("2025-01-31 23:59:59");
    if (today.isAfter(imageChangeDate)) {
      setBannerImage(jetdaiActivityBackground02);
      setMobileBannerImage(jetdaiActivityBackgroundMobile02);
    }
  }, [today, endDate]);

  if (!isInActivity) {
    return (
      <div className="jetdai-activity-out-of-period">
        活動已結束
      </div>
    );
  }

  const currentBannerImage = screens.xs ? mobileBannerImage : bannerImage;

  return (
    <div className="jetdai-activity">
      <FrontBreadcrumb
        link={[
          { href: "/about/jetdai", name: "Jet貸" },
          { href: "", name: "活動介紹" },
        ]}
      />

      <div className="jetdai-activity-banner" style={{ paddingTop: 40 }}>
        <a href="/about/jetdai/apply-step">
          <img src={currentBannerImage} alt="jetdai-activity-banner" />
        </a>
      </div>

      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
    </div>
  );
};

export default FrontGuide;
